import { IconLink, OwnUpUnderlineLink } from '@rategravity/own-up-component-library';
import { ArrowRightIcon } from '@rategravity/own-up-component-library/icon-library/system-icons/standard-icons/arrow-right';
import React from 'react';

// alphabetical order
export const stateData = [
  {
    state: 'Alabama',
    description: 'Alabama Mortgage Brokers',
    code: 'License #23141'
  },
  {
    state: 'Alaska',
    description: 'Alaska Mortgage Broker/Lender',
    code: 'License No. AK1450805'
  },
  {
    state: 'Arizona',
    description: 'Licensed by the Arizona Department of Insurance and Financial Institutions',
    code: 'License No. MB-1021912'
  },
  {
    state: 'Arkansas',
    description: 'Arkansas Security Department',
    code: 'Mortgage Broker License #128339'
  },
  {
    state: 'California',
    description: 'California Department of Real Estate, Real Estate Corporation',
    code: 'License Endorsement #02134434'
  },
  {
    state: 'Colorado',
    description: (
      <>
        <span>Mortgage Company Registration, Regulated by the </span>
        <OwnUpUnderlineLink link="https://dre.colorado.gov/" target="_blank" rel="noreferrer">
          Division of Real Estate
        </OwnUpUnderlineLink>
      </>
    ),
    code: 'License No. 1450805'
  },
  {
    state: 'Connecticut',
    description: 'Licensed as a Mortgage Broker, Department of Banking',
    code: 'License No. MB-1450805'
  },
  {
    state: 'Delaware',
    code: 'Delaware Broker License # 037098'
  },
  {
    state: 'District of Columbia',
    description:
      'Licensed by the District of Columbia Department of Insurance, Securities and Banking',
    code: 'License No. MLB1450805'
  },
  {
    state: 'Florida',
    description: 'Licensed as a Mortgage Broker by the Office of Financial Regulation',
    code: 'License No. MBR2922'
  },
  {
    state: 'Georgia',
    description: (
      <>
        Georgia Residential Mortgage Licensee
        <br />
        Licensed as a Mortgage Broker/Processor by the Department of Banking and Finance
      </>
    ),
    code: 'No. 67042'
  },
  {
    state: 'Idaho',
    description: 'Idaho Department of Finance',
    code: 'Mortgage Broker/Lending License MBL-2081450805'
  },
  {
    state: 'Illinois',
    description: 'Illinois Residential Mortgage',
    code: 'License No. MB.6761609'
  },
  {
    state: 'Indiana',
    description: 'SOS Loan Broker',
    code: 'License No. 1450805'
  },
  {
    state: 'Iowa',
    description: 'Iowa Division of Banking',
    code: 'Broker License License #2022-0062'
  },
  {
    state: 'Kansas',
    description: 'Kansas Office of the State Bank Commissioner',
    code: 'Mortgage Company License #MC.0025790'
  },
  {
    state: 'Kentucky',
    description: 'Kentucky Department of Financial Institutions',
    code: 'Mortgage Broker License #MB785601'
  },
  {
    state: 'Louisiana',
    description: 'Louisiana Office Of Financial Institutions',
    code: 'Residential Mortgage Lending License'
  },
  {
    state: 'Maine',
    description: 'Licensed as a Loan Broker by the Bureau of Consumer Credit Protection',
    code: 'License No. 1450805'
  },
  {
    state: 'Maryland',
    description:
      'Licensed by the Maryland of Labor, Office of the Commissioner of Financial Regulation',
    code: 'License No. 06-25073'
  },
  {
    state: 'Massachusetts',
    description: 'Mortgage Broker',
    code: 'License No. MB1450805'
  },
  {
    state: 'Michigan',
    description: 'Department of Insurance and Financial Services 1st Mortgage Broker License',
    code: 'License No. FL0022270'
  },
  {
    state: 'Minnesota',
    description: 'Licensed by the Commerce Department Financial Institutions Division',
    code: 'Residential Mortgage Originator License # MN-MO-1450805'
  },
  {
    state: 'Mississippi',
    description:
      'Licensed by the Mississippi Department of Banking and Consumer Finance as a Mississippi Mortgage Broker',
    code: 'License No. 1450805'
  },
  {
    state: 'Missouri',
    description: 'Missouri Mortgage Company',
    code: 'License #1450805-LG'
  },
  {
    state: 'Montana',
    description: 'Montana Division of Banking & Financial Institutions',
    code: 'Mortgage Broker License #1450805'
  },
  {
    state: 'Nebraska',
    description: 'Nebraska Department of Banking and Finance',
    code: ''
  },
  {
    state: 'New Hampshire',
    description: 'Licensed by the New Hampshire Banking Department',
    code: 'Mortgage Broker License No. 22638-MBR'
  },
  {
    state: 'New Jersey',
    description: 'New Jersey Residential Mortgage Broker',
    code: 'License NMLS ID # 1450805'
  },
  {
    state: 'New Mexico',
    code: 'Mortgage Loan Company License NMLS # 1450805'
  },
  {
    state: 'North Carolina',
    code: 'Mortgage Broker License - B-198370'
  },
  {
    state: 'North Dakota',
    description: 'North Dakota Money Broker License',
    code: 'License No. MB103977'
  },
  {
    state: 'Ohio',
    description: 'Licensed by the Department of Commerce, Division of  Financial Institutions',
    code: 'Mortgage Lending Act Certificate of Registration RM.804688.000'
  },
  {
    state: 'Oklahoma',
    code: 'Mortgage Broker License # MB014685'
  },
  {
    state: 'Oregon',
    description: 'Oregon Division of Financial Regulation',
    code: 'Mortgage Lending License 1450805'
  },
  {
    state: 'Pennsylvania',
    description: 'Licensed by the Department of Banking and Securities',
    code: 'Mortgage Broker License No. 70860'
  },
  {
    state: 'Rhode Island',
    description: 'Licensed by the Rhode Island Division of Banking',
    code: 'Loan Broker License No. 20193713LB'
  },
  {
    state: 'South Carolina',
    description: 'South Carolina Department of Consumer Affairs - Mortgage Broker License'
  },
  {
    state: 'South Dakota',
    description: 'South Dakota Mortgage Brokerage',
    code: 'License #1450805MB'
  },
  {
    state: 'Tennessee',
    code: 'Mortgage License No. 220781'
  },
  {
    state: 'Texas',
    code: 'NMLS No. 1450805',
    extra: (
      <section aria-label="texas disclaimer">
        Consumers wishing to file a complaint against a mortgage banker or a licensed mortgage
        banker residential mortgage loan originator should complete and send a complaint form to:
        <p>
          Texas Department of Savings and Mortgage Lending
          <br />
          2601 North Lamar, Suite 201 Austin, Texas
          <br />
          78705
        </p>
        <p>
          Complaint forms and instructions may be obtained from the department’s website at{' '}
          <OwnUpUnderlineLink link="https://www.sml.texas.gov" target="_blank" rel="noreferrer">
            www.sml.texas.gov
          </OwnUpUnderlineLink>
          . A toll-free consumer hotline is available at{' '}
          <OwnUpUnderlineLink link="tel:18772765550">1-877-276-5550</OwnUpUnderlineLink>.
        </p>
        <p>
          The department maintains a recovery fund to make payments of certain actual out of pocket
          damages sustained by borrowers caused by acts of licensed residential mortgage loan
          originators. A written application for reimbursement from the recovery fund must be filed
          with and investigated by the department prior to the payment of a claim. For more
          information about the recovery fund, please consult the department’s website at{' '}
          <OwnUpUnderlineLink link="https://www.sml.texas.gov" target="_blank" rel="noreferrer">
            www.sml.texas.gov
          </OwnUpUnderlineLink>
          .
        </p>
        Mortgage Company License No. 1450805
      </section>
    )
  },
  {
    state: 'Vermont',
    description: 'Vermont Mortgage Broker License',
    code: 'License Number: MB-1450805'
  },
  {
    state: 'Virginia',
    description: 'Virginia Broker License',
    code: 'License No. MC-7353'
  },
  {
    state: 'West Virginia',
    description: 'Mortgage Broker',
    code: 'License No. MB-1450805'
  },
  {
    state: 'Wisconsin',
    description: 'Licensed by the Department of Financial Institutions',
    code: 'License No. 1450805BR'
  },
  {
    state: 'Wyoming',
    description: 'Wyoming Division of Banking',
    code: 'Mortgage Broker License #4504'
  },
  {
    state: 'RateGravity Inc. d/b/a Own Up Federal Disclosures',
    code: 'NMLS#: 1450805',
    extra: (
      <IconLink
        link="https://www.nmlsconsumeraccess.org/"
        target="_blank"
        rel="noopener"
        icon={<ArrowRightIcon />}
      >
        NMLS consumer access page
      </IconLink>
    )
  }
];
